<template>
  <v-btn color="green white--text" @click="chimeIn">
    <div>
      <!-- <img class="froggy" src="/src/images/PeekPicaClear.png"> -->
      Chime In!
    </div>
    <!-- <div class="holder">
      <div class="message">
        <img class="froggy" src="/src/images/PeekPicaClear.png">
        Copy this list
        <img class="froggy" src="/src/images/PeekPicaClear.png">
        Make your own
        <img class="froggy" src="/src/images/PeekPicaClear.png">
        Chime In!!!
      </div>
    </div> -->
  </v-btn>
</template>
<script>
  import api from '/src/utils/api';
  import {isLoggedIn, login} from '/src/utils/auth';

  const ChimeInButton = {
    data() {
      return {
      }
    },
    methods: {
      chimeIn() {
        if (!isLoggedIn()) {
          // We're setting this flag so that when the user returns from logging in, we proceed with the list copy.
          localStorage.setItem('isChiming',1);
          login();
        }
        else
          this.copyList();
      },
      copyList() {
        // Copy the list which the user is viewing so they have their own list with the same name.
        // Upon a successful copy, route the user to their copy of the list.
        let copyFromListId = this.viewingListId; //localStorage.getItem('viewingListId');
        let copyFromListName = this.viewingListName; //localStorage.getItem('viewingListName');
        let toChannelId = localStorage.getItem('primaryChannelId');
        let toChannelName = localStorage.getItem('primaryChannelName');

        api.postAuthRequired('chime-list', {copyFromListId, copyFromListName, toChannelId}).then((res) => {
            window.location.href = "/" + toChannelName + "/" + copyFromListName;
        });
      },
    },
    mounted() {
      // If this value exists, it suggests we just came from logging in after an attempt to Chime In.
      if (localStorage.getItem('isChiming') == 1) {
        localStorage.setItem('isChiming', 0);

        // We still confirm the login in case the user cancelled the process.
        if ( isLoggedIn() ) {
          this.copyList();
        }
      }
    },
    props: ['viewingListId','viewingListName'],
  }
  export default ChimeInButton;
</script>
<style>
    .holder {
        padding:0.5rem;
        overflow: hidden;
    }
    .message {
        color: white;
        animation : slide 10s linear infinite;
    }
    .froggy {
        height: 20px;
    }
    @keyframes slide {
        0% {
            transform: translatex(0%)
        }

        100% {
            transform: translatex(100%)
        }
    }
</style>
